<template>
	<div class="grey lighten-5 fill-height" v-if="siteReady" :style="style">
		<top-bar class="top-bar" />
		<v-main>
			<div class="container content-pt">
				<router-view />
			</div>
			<contact />
		</v-main>
		<footer-bar />
	</div>
</template>

<script>
import { INIT_COMPANY } from '@/store/modules/company.module';
import FooterBar from '@/views/_shared/layout/enduser/Footer.vue';
import TopBar from '@/views/_shared/layout/enduser/TopBar.vue';
import Contact from '@/views/_shared/layout/listing/Contact.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'ErrorBase',
	components: {
		TopBar,
		FooterBar,
		Contact,
	},
	data() {
		return {
			initialized: false,
		};
	},
	computed: {
		...mapGetters([
			'getSite',
			'templateHexColor',
			'templateTextHexColor',
			'darkTemplate',
			'buttonBackColor',
			'buttonTextColor',
			'headerBackColor',
			'headerTextColor',
			'footerBackColor',
			'footerTextColor',
		]),
		isTopBarShow() {
			return this.getSite?.showContactInfoHeader;
		},
		style() {
			return {
				'--v-toolbar--content': this.isTopBarShow ? '120px' : '95px',
			};
		},
		siteReady() {
			return this.getSite && this.initialized;
		},
		gTagId() {
			return this.getSite.gTagId;
		},
		gTagManagerId() {
			return this.getSite.gTagManagerId;
		},
		pixel() {
			return this.getSite.pixel;
		},
	},
	mounted() {},
	created() {
		this.$nextTick(() => {
			this.initSite();
		});
	},
	methods: {
		...mapActions({
			initCompany: INIT_COMPANY,
		}),

		initSite() {
			this.initCompany().then(() => {
				this.initialized = true;
			});
		},
	},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');
* {
	font-family: 'Questrial', sans-serif;
}

.v-application {
	background-color: var(--v-background-base) !important;
	color: var(--v-lg-primary-text) !important;

	.error {
		&--text {
			color: var(--v-contrast-color) !important;
		}
	}
	.contrast-color {
		color: var(--v-contrast-color) !important;
	}
}

.content-pt {
	padding-top: var(--v-toolbar--content) !important;
	min-height: 500px;
}
.contact-container {
	padding: 5rem 1.5rem 6rem;
}
.top-bar {
	position: relative;
}
</style>
