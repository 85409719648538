<template>
	<div
		class="template-text--text contact-container d-flex flex-column align-center justify-space-between"
		style="min-height: 320px"
	>
		<div class="text-h4 font-weight-bold contact-title">Bize Ulaşın</div>
		<div class="text-h6 mt-5 mb-mt-0 text-center text-md-left" v-if="false">
			Müşteri temsilcilerimiz; hafta içi 09:00-19:00, hafta sonu 09:00-18:00 saatleri arasında size destek olmak için
			hazır.
		</div>
		<div class="d-flex mt-16 justify-space-between flex-column flex-md-row">
			<div class="d-flex flex-row align-center mb-5 mb-md-0 mr-0 mr-md-16" v-if="false">
				<div class="d-flex align-center justify-space-between">
					<div class="template-text pa-3 rounded-lg mr-3">
						<v-icon class="font-weight-bold" v-text="'fab fa-whatsapp'" light></v-icon>
					</div>
					<div class="d-flex flex-column">
						Whatsapp ile iletişime geçin
						<span class="font-weight-bold">+90 532 654 1234</span>
					</div>
				</div>
			</div>
			<div class="d-flex flex-row align-center mb-5 mb-md-0 mr-0 mr-md-16" v-if="getSite.contactUsEMail">
				<div class="d-flex align-center justify-space-between flex-wrap">
					<div class="template-text pa-3 rounded-lg mr-3">
						<v-icon color="template-color" v-text="'fa-phone-alt'"></v-icon>
					</div>
					<div class="d-flex flex-column">
						Bizi arayın
						<span class="font-weight-bold">{{ getSite.contactUsPhone | VMask(phoneMask) }}</span>
					</div>
				</div>
			</div>
			<div class="d-flex flex-row align-center" v-if="getSite.contactUsEMail">
				<div class="d-flex align-center justify-space-between flex-wrap">
					<div class="template-text pa-3 rounded-lg mr-3">
						<v-icon color="template-color" v-text="'fas fa-at'"></v-icon>
					</div>
					<div class="d-flex flex-column">
						E-posta gönderin
						<span class="font-weight-bold"
							><a class="link" :href="`mailto:${getSite.contactUsEMail}`"> {{ getSite.contactUsEMail }}</a></span
						>
					</div>
				</div>
			</div>
		</div>
		<WorkHours v-if="isWorkHoursShow" page="contact" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import WorkHours from '@/views/enduser/pages/_shared/WorkHours.vue';

export default {
	name: 'Contact',
	components: {
		WorkHours,
	},
	computed: {
		...mapGetters(['getSite']),
		isWorkHoursShow() {
			return this.getSite?.workHours.length > 0;
		},
		phoneMask() {
			if (this.getSite?.phone?.startsWith('444')) return '### # ###';

			return '## (###) ### ## ##';
		},
	},
};
</script>

<style lang="scss" scoped>
.contact-container {
	.link {
		text-decoration: none !important;
		color: var(--v-template-text-base);
	}
	padding: 5rem 1.5rem 6rem;
	background: var(--v-template-color-base);
	background: linear-gradient(
		-40deg,
		var(--v-template-color-darken1) 0%,
		var(--v-template-color-base) 50%,
		var(--v-template-color-darken3) 100%
	);

	.contact-title {
		text-shadow: 2px 3px 1px var(--v-template-color-darken2);
	}
}
</style>
